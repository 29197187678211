
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import AdminButton from "@/components/AdminButton.vue";
import PageTitle from "@/components/PageTitle.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseInput from "@/components/BaseInput.vue";

export default {
  name: "Home",
  components: {
    PageTitle,
    AdminButton,
    BaseIcon,
    BaseInput
  },
  data() {
    return {
      clicked: 0,
      text: "",
      text2: "",
      pages: [
        {
          title: "Add Ons",
          link: "/add-ons"
        },
        {
          title: "FAQs",
          link: "/faqs"
        },
        {
          title: "Help Videos",
          link: "/help-videos"
        },
        {
          title: "Images",
          link: "/images"
        },
        { title: "Testimonies", link: "/testimonies" },
        {
          title: "Gift Card Fees",
          link: "/gift-card-fees"
        }
      ].sort((a, b) => {
        return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
      }),
      angularAdmin: process.env.VUE_APP_PIXINGO_SERVER + "/admin"
    };
  }
};
