import axios from "axios";
import { makeFaqAnswerHtml } from "@/utils";
const baseURL = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/faqs/";
export default {
  namespaced: true,
  state: {
    list: [],
    newFaq: {
      question: "",
      answer: ""
    }
  },
  mutations: {
    create(state, item) {
      // console.log("creating state from", state.list, item);
      state.list = [...state.list, item];
    },
    update(state, item) {
      state.list = state.list.map(sitem => {
        return sitem.id === item.id ? Object.assign({}, sitem, item) : sitem;
      });
    },
    delete(state, item) {
      state.list = state.list.filter(sitem => sitem.id !== item.id);
    },

    load(state, items) {
      state.list = items;
    }
  },
  actions: {
    async getAll({ state, commit }) {
      if (state.list.length) return;

      try {
        let response = await axios.get(baseURL);
        let faqs = response.data;
        faqs.forEach(item => {
          item.answerHtml = makeFaqAnswerHtml(item.answer);
        });

        commit("load", faqs);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteFaq({ commit }, item) {
      try {
        let url = baseURL + item.id;
        await axios.delete(url);
        commit("delete", item);
      } catch (err) {
        console.log("error deleteing");
      }
    },
    async createFaq({ commit }, item) {
      try {
        let results = await axios.post(baseURL, item);
        console.log("Create results", results);
        let faq = results.data;
        faq.answerHtml = makeFaqAnswerHtml(faq.answer);
        commit("create", results.data);
      } catch (err) {
        console.log("error deleteing");
      }
    },
    async updateFaq({ commit }, item) {
      try {
        let url = baseURL + item.id;
        await axios.put(url, item);
        //console.log("update results", results);
        //item.answerHtml = makeFaqAnswerHtml(item.answer);

        commit("update", item);
      } catch (err) {
        console.log("error deleteing");
      }
    }
  }
};
