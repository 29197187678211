import axios from "axios";
import { categoryOption } from "@/constants/add-on-const.js";
export default {
  namespaced: true,
  state: {
    list: [],
    selectedAddOn: {},
    newAddOn: {
      title: "",
      visible: false,
      priority: 0,

      category: categoryOption.GIFT,
      image_url: "",
      description: "",

      points: 0,
      expense: 0,
      print_code: "",
      gift_card_amounts: "",
      addOnCustomUsers: []
    }
  },
  mutations: {
    create(state, addOn) {
      console.log("creating state from", state.list, addOn);
      state.list = [...state.list, addOn];
    },
    update(state, addOn) {
      state.list = state.list.map(item => {
        return item.id === addOn.id ? Object.assign({}, item, addOn) : item;
      });
    },
    delete(state, addOn) {
      state.list = state.list.filter(item => item.id !== addOn.id);
    },

    //Not sure if this is in use
    load(state, addOns) {
      //   console.log("loading addons", addOns);
      state.list = addOns;
    },
    updateCustomUsers(state, users) {
      state.addOnCustomUsers = users;
    }
  },
  actions: {
    async getAll({ state, commit }) {
      if (state.list.length) return;

      try {
        let url =
          process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/add-ons?admin=1";
        // console.log("url", url);
        let response = await axios.get(url);
        let addOns = response.data;
        addOns.forEach(item => {
          if (item.category === categoryOption.GIFT_CARD) {
            item.gift_card_amounts_list = item.gift_card_amounts.split(",");
          }
        });

        commit("load", addOns);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
