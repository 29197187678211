
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
