import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Images from "../views/Images.vue";
import Testimonies from "../views/Testimonies.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/add-ons",
    name: "AddOns",
    component: () =>
      import(/* webpackChunkName: "add-ons" */ "../views/AddOns.vue")
  },
  {
    path: "/add-ons/:id",
    name: "AddOnsDetail",

    component: () =>
      import(/* webpackChunkName: "add-ons" */ "../views/AddOnsDetail.vue")
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: () => import(/* webpackChunkName: "faqs" */ "../views/Faqs.vue")
  },
  {
    path: "/faqs/:id",
    name: "FaqsDetail",
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/FaqsDetail.vue")
  },
  {
    path: "/gift-card-fees",
    name: "GiftCardFees",
    component: () =>
      import(
        /* webpackChunkName: "gift-card-fees" */ "../views/GiftCardFees.vue"
      )
  },
  {
    path: "/help-videos",
    name: "HelpVideos",
    component: () =>
      import(/* webpackChunkName: "help-videos" */ "../views/HelpVideos.vue")
  },
  {
    path: "/help-videos/:id",
    name: "HelpVideoDetail",
    component: () =>
      import(
        /* webpackChunkName: "help-videos" */ "../views/HelpVideoDetail.vue"
      )
  },
  {
    path: "/images",
    name: "Images",
    component: Images
  },
  {
    path: "/testimonies",
    name: "Testimonies",
    component: Testimonies
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
