import axios from "axios";
const baseURL = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/gift-card-fees";
export default {
  namespaced: true,
  state: { fees: [] },
  mutations: {
    save(state, fees) {
      state.fees = fees;
    }
  },
  actions: {
    async get({ state, commit }) {
      if (state.fees.length) return;
      try {
        let response = await axios.get(baseURL);
        //   console.log("got response", response);
        let fees = response.data;

        commit("save", fees);
      } catch (err) {
        console.log("err", err);
      }
    },
    async save({ state, commit }, fees) {
      try {
        await axios.post(baseUrl, fees);
        commit("save", fees);
      } catch (err) {
        console.log("err", err);
      }
    }
  }
};
