
import BaseIcon from "@/components/BaseIcon.vue";

export default {
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      default: "Confirm Delete"
    },
    body: {
      type: String,
      default: ""
    },
    confirmTitle: {
      type: String,
      default: "Yes, Delete"
    },
    cancelTitle: {
      type: String,
      default: "Cancel"
    }
  }
};
