
export default {
  props: ["modelValue", "label", "description", "placeholder"],
  computed: {
    id() {
      return this.label ? this.label.toLowerCase() : "text";
    },
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  }
};
