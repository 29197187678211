
export default {
  props: ["testimony"],
  data() {
    return {
      s3Folder: process.env.VUE_APP_S3_TESTIMONY_FOLDER
    };
  },

  computed: {},

  methods: {
    save(item) {
      console.log("emit save", item);
      this.$emit("save", item);
    },
    cancel() {
      this.$emit("cancel");
    },
    onUploadedImage(event) {
      console.log("onupload image", event);
      this.testimony.profile_image_url = event.image_url;
    }
  }
};
