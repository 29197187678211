
/* Example 
 <BaseInput
        class="mt-10"
        v-model="text"
        label="fancy label"
        id="input id"
        type="text"
        @keyup.enter="text2 = 'I did it'"
      ></BaseInput>
*/
export default {
  inheritAttrs: "false",
  name: "BaseInput",
  props: {
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    modelValue: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: { type: String, default: "" }
  },
  methods: {
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
};
