
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 2
    }
  },
  computed: {
    id() {
      return this.label ? this.label.toLowerCase() : "text";
    },
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  }
};
