
import axios from "axios";
const API_ENDPOINT =
  "https://dc3tkagyl0.execute-api.us-east-2.amazonaws.com/uploads";

const MAX_IMAGE_SIZE = 10000000;
const UploadStates = {
  None: "None",
  Started: "Uploading",
  Done: "Done"
};

export default {
  components: {},

  props: {
    image_url: {
      type: String,
      default: ""
    },
    s3Folder: {
      type: String,
      default: "misc"
    },
    options: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      image: "",
      new_image_url: "",

      imageExtention: "",
      imageContentType: "",
      dragClass: "",
      uploadPercentage: 25,
      uploadState: UploadStates.None,
      UploadStates
    };
  },
  methods: {
    noop() {},

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // var image = new Image()
      let reader = new FileReader();
      reader.onload = this.onLoadImage;
      reader.readAsDataURL(file);
    },
    removeImage: function(e) {
      console.log("Remove clicked");
      this.image = "";
    },
    uploadImage: async function(e) {
      this.uploadState = UploadStates.Uploading;
      console.log("Upload clicked");
      // Get the presigned URL
      let folder = this.s3Folder;
      let url =
        API_ENDPOINT +
        "?type=" +
        this.imageExtension +
        "&folder=" +
        encodeURI(folder);
      try {
        const response = await axios({
          method: "GET",
          url: url
        });
        // console.log("Response: ", response.data);
        let binary = atob(this.image.split(",")[1]);
        let array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blobData = new Blob([new Uint8Array(array)], {
          type: this.imageContentType
        });
        await axios.put(response.data.uploadURL, blobData, {
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            this.imageProgressStyle = "width:" + this.uploadPercentage + "%";
          }.bind(this)
        });

        // Final URL for the user doesn't need the query string params
        this.new_image_url = response.data.uploadURL.split("?")[0];

        this.uploadState = UploadStates.Done;
      } catch (err) {
        console.log("got an error", err);
      }
    },
    async onLoadImage(e) {
      //   console.log("e.target.result", e.target.result);
      if (
        !e.target.result.includes("data:image/jpeg") &&
        !e.target.result.includes("data:image/png")
      ) {
        return alert("Wrong file type - JPG,PNG only.");
      }

      if (e.target.result.length > MAX_IMAGE_SIZE) {
        return alert("Image is loo large.");
      }
      this.imageExtension = e.target.result.includes("data:image/jpeg")
        ? "jpg"
        : "png";
      this.imageContentType = e.target.result.includes("data:image/jpeg")
        ? "image/jpeg"
        : "image/png";
      this.image = e.target.result;
      await this.uploadImage();

      console.log("done uploading image");
      this.$emit("uploaded", {
        // image: this.image,
        // extension: this.imageExtension,
        // contentType: this.imageContentType,
        image_url: this.new_image_url,
        options: this.options
      });
    },
    drop(e) {
      console.log("drop", e);
      let files = e.dataTransfer.files;

      let file = files[0];

      let reader = new FileReader();
      reader.onload = this.onLoadImage;
      reader.readAsDataURL(file);
      this.setDragOverClass(false);
    },

    setDragOverClass(over) {
      this.dragClass = over ? "border-pixblue-400 bg-pixblue-100" : "";
    }
  }
};
