
/*
  use the names from https://heroicons.com/
Examples
      <BaseIcon class="text-red-800 w-7 h-7" icon="annotation" :solid="true"/>
      <BaseIcon class="w-10 h-10 text-blue-800" icon="archive" />
      <BaseIcon class="w-5 h-5 text-green-400" icon="arrow-down" />
*/
import outlineIcons from "@heroicons/vue/outline/index";
import solidIcons from "@heroicons/vue/solid/index";

export default {
  props: {
    icon: {
      type: String,
      require: true
    },
    solid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    heroIconComponent: function() {
      let parts = this.icon.split("-");
      parts = parts.map(part => this.capitalizeFirstLetter(part));
      parts.push("Icon");
      let name = parts.join("");
      return this.solid ? solidIcons[name] : outlineIcons[name];
    }
  },
  methods: {
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
