import Vuex from "vuex";
import addOnStore from "./modules/addOnStore.js";
import faqStore from "./modules/faqStore.js";
import giftCardFeeStore from "./modules/giftCardFeeStore.js";
import helpVideoStore from "./modules/helpVideoStore.js";

const createStore = () => {
  return new Vuex.Store({
    modules: {
      addOns: addOnStore,
      faqs: faqStore,
      giftCardFees: giftCardFeeStore,
      helpVideos: helpVideoStore
    }
  });
};
export default createStore();
