
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      testimonies: [],
      selected: {},
      new_testimony: {
        name: "",
        location: "",
        text: ""
      },
      editTestimony: false,
      confirmDelete: false
    };
  },
  async mounted() {
    this.testimonies = await this.getTestimonies();
    console.log("got testimonies", this.testimonies);
  },
  methods: {
    startNew() {
      console.log("starting to create a new one");
      this.selected = Object.assign({}, this.new_testimony);
      this.showTestimonyModal(this.selected);
    },
    async saveTestimony(value) {
      console.log("saving testimony", value);
      try {
        if (!value.id) {
          this.testimonies = await this.addTestimony(this.testimonies, value);
        } else {
          this.testimonies = await this.updateTestimony(
            this.testimonies,
            value
          );
        }
        this.editTestimony = false;
      } catch (err) {
        console.log("err", err);
      }
    },
    async addTestimony(collection, item) {
      console.log("add id", item);
      try {
        let url = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/testimonies";
        let results = await axios.post(url, item);
        item.id = results.data.id;
        console.log("saved id", item.id);

        return [item, ...collection];
      } catch (err) {
        console.log("got err", err);
      }
    },
    async updateTestimony(collection, item) {
      console.log("update id", item);
      try {
        let url =
          process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/testimonies/" + item.id;
        await axios.put(url, item);
        return collection.map(e =>
          e.id === item.id ? Object.assign({}, item) : e
        );
      } catch (err) {
        console.log("unable to update", err);
        return collection;
      }
    },
    startDeleteTestimony(item) {
      this.selected = item;
      this.confirmDelete = true;
    },
    async handleConfirmDelete() {
      this.testimonies = await this.deleteOne(this.testimonies, this.selected);
      this.confirmDelete = false;
    },
    async deleteOne(collection, item) {
      let url =
        process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/testimonies/" + item.id;
      await axios.delete(url, item);
      return collection.filter(i => i.id !== item.id);
    },

    showTestimonyModal(testimony) {
      this.selected = Object.assign({}, testimony);
      this.editTestimony = true;
    },
    async getTestimonies() {
      let url = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/testimonies";
      let results = await axios.get(url);
      return results.data;
    }
  }
};
