
import AddButton from "@/components/AddButton.vue";

export default {
  components: {
    AddButton
  },

  props: ["title", "addRoute", "add"]
};
