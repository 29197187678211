import axios from "axios";
const baseURL = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/help-videos/";
export default {
  namespaced: true,
  state: {
    list: [],
    newHelpVideo: {
      title: "",
      url: ""
    }
  },
  mutations: {
    create(state, item) {
      // console.log("creating state from", state.list, item);
      state.list = [...state.list, item];
    },
    update(state, item) {
      state.list = state.list.map(state_item => {
        return state_item.id === item.id
          ? Object.assign({}, state_item, item)
          : state_item;
      });
    },
    delete(state, item) {
      state.list = state.list.filter(state_item => state_item.id !== item.id);
    },

    load(state, items) {
      state.list = items;
    }
  },
  actions: {
    async getAll({ state, commit }) {
      console.log("getting");
      if (state.list.length) return;

      try {
        let response = await axios.get(baseURL);
        let helpVideos = response.data;
        console.log("got helpVideos", helpVideos.length);

        commit("load", helpVideos);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteHelpVideo({ commit }, item) {
      try {
        let url = baseURL + item.id;
        await axios.delete(url);
        commit("delete", item);
      } catch (err) {
        console.log("error deleteing");
      }
    },
    async createHelpVideo({ commit }, item) {
      try {
        let results = await axios.post(baseURL, item);
        console.log("Create results", results);
        let output = results.data;

        commit("create", output);
      } catch (err) {
        console.log("error deleteing");
      }
    },
    async updateHelpVideo({ commit }, item) {
      try {
        let url = baseURL + item.id;
        let results = await axios.put(url, item);
        console.log("update results", results);

        commit("update", item);
      } catch (err) {
        console.log("error deleteing");
      }
    },
    async saveSequence({ state }) {
      let index = 0;

      for (let helpVideo of state.list) {
        helpVideo.sequence = index++;
        let url = baseURL + helpVideo.id;

        await axios.put(url, helpVideo);
      }
    }
  }
};
