
import axios from "axios";
const baseUrl = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/marketing-images";
export default {
  data() {
    return {
      images: [],
      s3Folder: process.env.VUE_APP_S3_BASE_FOLDER + "/marketing-images"
    };
  },
  async mounted() {
    this.images = await this.getImages();
    console.log("this.image", this.images);
  },
  methods: {
    async onUploadedImage(event) {
      let image = this.images.find(image => image.id === event.options.id);
      if (image) {
        let new_image = {
          id: image.id,
          url: event.image_url
        };

        await this.updateImage(this.images, new_image);
        console.log("done saving");
      }
    },
    async updateImage(collection, item) {
      console.log("update id", item);
      try {
        let url = baseUrl + "/" + item.id;
        await axios.put(url, item);
        return collection.map(e =>
          e.id === item.id ? Object.assign({}, item) : e
        );
      } catch (err) {
        console.log("unable to update", err);
        return collection;
      }
    },

    async getImages() {
      let results = await axios.get(baseUrl);

      return results.data;
    }
  }
};
